define('js/slider-widget',[
    'jquery',
    'Magento_PageBuilder/js/events',
    'slick'
], function ($, events) {
    'use strict';

    return function (config, sliderElement) {
        var $element, bannerSelector, playBtn, dotsWrapper;
    
        $element = $(sliderElement);
        bannerSelector = $('#MainBanner');
        playBtn = $('#MainBanner #PLAY');
        dotsWrapper = $('#MainBanner .dots-wrapper');

        /**
         * Prevent each slick slider from being initialized more than once which could throw an error.
         */

        if ($element.hasClass('slick-initialized')) {
            $element.slick('unslick');
        }

        $element.slick({
            autoplay: $element.data('autoplay'),
            autoplaySpeed: $element.data('autoplay-speed') || 0,
            fade: $element.data('fade'),
            infinite: $element.data('is-infinite'),
            arrows: $element.data('show-arrows'),
            dots: $element.data('show-dots'),
            appendDots: '.dots-wrapper'
        });

        // Redraw slide after content type gets redrawn
        events.on('contentType:redrawAfter', function (args) {
            if ($element.closest(args.element).length) {
                $element.slick('setPosition');
            }
        });

        /**
         * Show navigation panel after banner loaded
         */

        if (dotsWrapper.length > 0 && dotsWrapper.hasClass('_hidden')) {
            dotsWrapper.removeClass('_hidden');
        }

        /**
         * Add Play/Pause functionality
         */

        if (playBtn.length > 0) {
            playBtn.on('click', function () {
                if (playBtn.hasClass('played')) {
                    $element.slick('slickPause');
                    playBtn
                        .removeClass('played')
                        .addClass('paused');
                } else if (playBtn.hasClass('paused')) {
                    $element.slick('slickPlay');
                    playBtn
                        .removeClass('paused')
                        .addClass('played');
                }
            });
        }
        
        /**
         * Add functionality to set height for image wrapper = mobile image height
         * for mobile images only to make them scaled (hidden from left-right) for different device width
         */

        if (bannerSelector.length > 0) {
            var mobileSlide, mobileSlideImage, mobileHeight,
                elementSlideWrapper, dotElement, wrapperElement, hidden;
            
            mobileSlide = bannerSelector.find('.slick-slide:not(.slick-cloned)');
            mobileSlideImage = mobileSlide.find('.pagebuilder-mobile-only')[0];
            mobileHeight = mobileSlideImage.naturalHeight;
            elementSlideWrapper = bannerSelector.find('.widget.block').find("figure[data-content-type='image']");

            $.each(elementSlideWrapper, function (index, value) {
                value.style.height = mobileHeight + 'px';
            });
        }

        /**
         * Add Pause functionality when clicked on dot
         */

        dotElement = $('#MainBanner .slick-dots li');

        if (dotElement.length > 0) {
            $.each(dotElement, function (index, value) {
                $(value).on('click', function () {
                    if (playBtn.length > 0 && playBtn.hasClass('played')) {
                        $element.slick('slickPause');
                        playBtn
                            .removeClass('played')
                            .addClass('paused');
                    }
                });
            });
        }

        /**
         * Remove Banner Preload Block
         */

        wrapperElement = document.getElementById('BannerPreloader');
        hidden = wrapperElement.classList.contains('_hidden');

        if (wrapperElement && !hidden) {
            wrapperElement.classList.add('_hidden');
        }
    };
});

