define('js/plp-filters',[
    'jquery',
    'underscore',
    'matchMedia',
    'jquery-ui-modules/widget',
    'mage/cookies',
    'jquery/jquery.parsequery',
    'url-polyfill'
], function ($, _, mediaCheck) {
    'use strict';

    /**
     * Widget for Sidebar on a PLP (mobile version)
     */
    $.widget('giftcards.plpFilters', {
        options: {
            sidebar: '.sidebar-main',
            filter: '[data-role=filter]',
            filterLink: '.js-filter-link',
            filterInput: '.js-filter-input',
            filterForm: '.js-layered-navigation-form',
            filtersShowLink: '[data-role=filters-show-link]',
            filtersBlock: '[data-role=filters-block]',
            selectedFilter: '[data-role=selected-filter]',
            selectedFilterText: '[data-role=filter-selected-text]',
            filterResetValue: '[data-selected-on-reset=1]',
            doneButton: '[data-role=done-button]',
            clearOccasionsButton: '.js-clear-occasions',
            resetButton: '[data-role=reset-button]',
            filtersOpened: '_opened',
            filtersIsShows: '_filters-opened',
            mediaMobile: '(max-width: 1023px)',
            filterClosed: '#FilterClosed',
            filterShadow: '#FilterShadow'
        },

        resetFlag: false,

        /**
         * Bind context for event handlers.
         * @private
         */
        _bindHandlerContext: function () {
            _.bindAll(
                this,
                '_onClickFilterShowLinkHandler',
                '_onClickFilterLinkHandler',
                '_onClickDoneLinkHandler'
            );
        },

        /**
         * Create widget.
         * @private
         */
        _create: function () {
            this._bindHandlerContext();
            this._setHandlers();
            this._checkSidebar();
            this._setSelectedFilters();
            this._navFilterTabletPlus();
        },

        /**
         * Events for links.
         * @private
         */
        _setHandlers: function () {
            $(this.options.filtersShowLink).on('click touchend', this._onClickFilterShowLinkHandler);
            $(this.options.resetButton).on('click touched', this._resetFilters.bind(this));
            $(this.options.filterInput).on('change', this._setSelectedFilters.bind(this));
            $(this.options.clearOccasionsButton).on('click', this._clearOccasions.bind(this));

            mediaCheck({
                media: this.options.mediaMobile,
                entry: $.proxy(function () {
                    this._toggleMobileMode();
                }, this),
                exit: $.proxy(function () {
                    this._toggleDesktopMode();
                }, this)
            });
        },

        /**
         * toggle sidebar state.
         */
        toggleSidebarState: function () {
            var setAction = 'open';

            if ($(this.options.sidebar).hasClass(this.options.filtersOpened)) {
                setAction = 'close';
            }
            this._toggleSidebar(setAction);
        },

        /**
         * Open or close sidebar
         * @param {String} action - open or close
         * @private
         */
        _toggleSidebar: function (action) {
            if (action === 'open') {
                this._enableSidebar();
            } else {
                this._disableSidebar();
            }
        },

        /**
         * Show sidebar
         * @private
         */
        _enableSidebar: function () {
            $('body').addClass(this.options.filtersIsShows);
            $(this.options.sidebar).addClass(this.options.filtersOpened);
            $(this.options.filterShadow).fadeIn();
            this._setCookie();
        },

        /**
         * Hide sidebar.
         * @private
         */
        _disableSidebar: function () {
            $('body').removeClass(this.options.filtersIsShows);
            $(this.options.sidebar).removeClass(this.options.filtersOpened);
            $(this.options.filterShadow).fadeOut();
            this._destroyCookie();
        },

        /**
         * Reset Filters
         * @param e
         * @private
         */
        _resetFilters: function (e) {
            e.preventDefault();
            $(this.options.filterInput + '[name="type"]').first().prop('checked', true);
            $(this.options.filterInput + '[name="occasion"]').prop('checked', false);
            this._setSelectedFilters();
        },

        _setSelectedFilters: function () {
            var text,
                _self = this,
                selectedOptions;

            $(this.options.selectedFilterText).each(function () {
                text = '';
                selectedOptions = $(this).parents('.filter-options--list').find(_self.options.filterInput + ':checked');
                selectedOptions.each(function (i, el) {
                    text += $(el).siblings('label').text().trim();

                    if (selectedOptions.length - 1 !== i) {
                        text += ', ';
                    }
                });

                console.log(text);
                $(this).text(text);
            });

            if (text !== '') {
                $(this.options.clearOccasionsButton).addClass('filter-clear-occasions-visible');
            } else {
                $(this.options.clearOccasionsButton).removeClass('filter-clear-occasions-visible');
            }
        },

        /**
         * Check if Cookie exists.
         * @returns {null} or {string}
         * @private
         */
        _getCookie: function () {
            var cookie = $.mage.cookies.get('filters_opened') || null;

            return cookie;
        },

        /**
         * if Cookie exists - open sidebar.
         * @private
         */
        _checkSidebar: function () {
            if (this._getCookie()) {
                this._toggleSidebar('open');
            }
        },

        /**
         * set Cookie if not exists.
         * @private
         */
        _setCookie: function () {
            if (!this._getCookie()) {
                $.mage.cookies.set('filters_opened', '1');
            }
        },

        /**
         * Destroy cookie.
         * @private
         */
        _destroyCookie: function () {
            if (this._getCookie()) {
                $.mage.cookies.clear('filters_opened');
            }
        },

        /**
         * Toggle mobile mode.
         * @private
         */
        _toggleMobileMode: function () {
            $(this.options.filterInput).off('click');
            $(this.options.doneButton).on('click touchend', function (e) {
                this._onClickDoneLinkHandler(e);
            }.bind(this));
            $(this.options.filterInput).on('click touchend', this._onClickFilterLinkHandler);

        },

        /**
         * Toggle desktop mode.
         * @private
         */
        _toggleDesktopMode: function () {
            $(this.options.filterInput).off('click touch', this._onClickFilterLinkHandler);
            $(this.options.doneButton).off('click touchend');
            $(this.options.filterInput).on('click', this._submitFilters.bind(this));
        },

        /**
         * onClick() event handler for *filter links*.
         * @param e
         * @private
         */
        _onClickFilterLinkHandler: function () {
            this._setSelectedFilters();
            this._setCookie();
            this.resetFlag = false;
        },

        /**
         * onClick() event handler for *show link*.
         * @param e
         * @private
         */
        _onClickFilterShowLinkHandler: function (e) {
            e.preventDefault();
            this.toggleSidebarState();
        },

        /**
         * onClick() event handler for *done link*.
         * @param event
         * @private
         */
        _onClickDoneLinkHandler: function (event) {
            event.preventDefault();
            this._toggleSidebar('close');

            this._submitFilters(event);
        },

        /**
         * Form serialize to object
         * @param form
         * @private
         */
        _prepareFormObject: function (form) {
            return this._objectFromQueryString(form);
        },

        /**
         * Get existing query params
         * @returns {*}
         * @private
         */
        _prepareAdditionalQuery: function () {
            var search = window.location.search;

            return this._objectFromQueryString(window.location.search.slice(1, search.length));
        },

        /**
         * Convert query string to object
         * @param str
         * @private
         */
        _objectFromQueryString: function (str) {
            var queryString = str,
                queryObject = {},
                valuesArray,
                itemsArray,
                paramsArray = queryString.split('&');

            if (queryString.length) {
                for (const param of paramsArray) {
                    valuesArray = param.split('=');

                    if (queryObject[valuesArray[0]] !== undefined && typeof queryObject[valuesArray[0]] == 'object') {
                        queryObject[valuesArray[0]].push(valuesArray[1]);
                    } else if (queryObject[valuesArray[0]] !== undefined) {
                        itemsArray = [];
                        itemsArray[0] = queryObject[valuesArray[0]];
                        itemsArray[1] = valuesArray[1];
                        queryObject[valuesArray[0]] = itemsArray;
                    } else if (valuesArray[0] !== 'undefined' && valuesArray[0] === 'p') {
                        queryObject[valuesArray[0]] = 1;
                    } else {
                        queryObject[valuesArray[0]] = valuesArray[1];
                    }
                }
            }

            return queryObject;
        },

        /**
         * Combine params from search and selected items
         * @private
         */
        _getFullQueryObject: function () {
            var formObject = this._prepareFormObject($(this.options.filterForm).serialize()),
                searchObject = this._prepareAdditionalQuery(),
                neededKeys = this._getObjectDifference(),
                fullObject;

            if (!_.isEmpty(formObject)) {
                fullObject = _.clone(formObject);

                for (const i of neededKeys) {
                    fullObject[neededKeys[i]] = searchObject[neededKeys[i]];
                }
            }

            return fullObject;
        },

        /**
         * Get difference between Search Object and all filters
         * @returns {*}
         * @private
         */
        _getObjectDifference: function () {
            var searchObject = this._prepareAdditionalQuery(),
                searchObjectKeys = _.keys(searchObject),
                formObjectKeys = [];

            $(this.options.filterInput).each(function () {
                var name = $(this).attr('name');

                if (formObjectKeys.indexOf(name) === -1) {
                    formObjectKeys.push(name);
                }
            });

            return _.difference(searchObjectKeys, formObjectKeys);
        },

        /**
         * Get new query string
         * @returns {string}
         * @private
         */
        _getQueryString: function () {
            var obj = this._getFullQueryObject();

            return this._prepareQueryString(obj);
        },

        /**
         * Format object to query string
         * @param obj
         * @returns {string}
         * @private
         */
        _prepareQueryString: function (obj) {
            return Object.keys(obj).map(function (item) {
                return encodeURIComponent(item) + '=' + obj[item];
            }).join('&');
        },

        /**
         * Reset occasions filter
         * @param e
         * @private
         */
        _clearOccasions: function (e) {
            var queryObject = this._getFullQueryObject(),
                queryString = this._prepareQueryString(_.omit(queryObject, 'occasion'));

            e.preventDefault();

            window.location = $(this.options.filterForm).attr('action') + '?' + queryString;

        },

        /**
         * Apply filters
         * @private
         */
        _submitFilters: function () {
            window.location = $(this.options.filterForm).attr('action') + '?' + this._getQueryString();
        },

        /**
         * Show Clear All link on Desktop
         *
         */
        _showClearAllLinkDesktop: function () {
            this.options.clearOccasionsButton.css({display: 'block'});
        },

        /**
         * Hide Clear All link on Desktop
         *
         */
        _hideClearAllLinkDesktop: function () {
            this.options.clearOccasionsButton.css({display: 'none'});
        },

        _navFilterTabletPlus: function () {
            var self = this;
            var sidebarPanel;

            $(this.options.filterClosed).on('click touchend', function () {
                sidebarPanel = $(self.options.sidebar);
                if (sidebarPanel.hasClass("_opened")) {
                    sidebarPanel.removeClass("_opened");
                    $(self.options.filterShadow).fadeOut();
                    self._destroyCookie();
                }
            });
        }
    });

    return $.giftcards.plpFilters;
});

